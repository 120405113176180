
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

// 해당 내용 많아지면 전역 변수
const HEADER_HEIGHT = 44
const BOTTOM_HEIGHT = 75

export default Vue.extend<Data, Methods, Computed>({
  name: 'SwiperExampleVertical',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      pullToPrevent: false,
      swiperOption: {
        direction: 'vertical',
        height: window.innerHeight - HEADER_HEIGHT - BOTTOM_HEIGHT,
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
      },
    }
  },
  computed: {
    ...mapGetters('CategoryStore', ['getCategoryPlanex']),
    swiper() {
      const el = this.$refs.mySwiper as any
      return el.$swiper
    },
  },
  watch: {
    getCategoryPlanex() {
      this.swiper.init()
      this.$getAppHtml.scrollTo({ top: 0 })
    },
  },
  methods: {
    ...mapActions('CategoryStore', ['fetchCategoryPlanex']),
    onSlideChange() {
      if (this.swiper.realIndex === 0) {
        this.pullToPrevent = false
      } else {
        this.pullToPrevent = true
      }
    },
    moveToSlideTop() {
      this.$tracking(`celltique_gototop`)
      this.swiper.slideTo(0, 0)
    },
    goMore(item, index) {
      this.$tracking(`celltique_${this.$numbering(index + 1)}_more`)
      this.$router.push({
        name: 'CategoryExhibitionList',
        query: {
          promotionNo: String(item.promotionNo),
          categoryName: item.title,
          previousPage: 'categoryExhibitionMain',
        },
      })
    },
    handleRefresh() {
      this.fetchCategoryPlanex()
    },
  },
})

interface Data {
  pullToPrevent: boolean
  swiperOption: SwiperOption
}
interface Methods {
  // Store
  fetchCategoryPlanex(): Promise<void>
  // Component
  onSlideChange(): void
  moveToSlideTop(): void
  goMore(item: CategoryPlanex, index: number): void
  handleRefresh(): void
}
interface Computed {
  getCategoryPlanex: CategoryPlanex[]
  swiper: any
}
