
import Vue from 'vue'
import CategoryHeader from './common/CategoryHeader.vue'
import CarouselExhibition from './common/CarouselExhibition.vue'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryExhibitionMainView',
  components: {
    CategoryHeader,
    CarouselExhibition,
  },
})

interface Data {}
interface Methods {}
interface Computed {}
