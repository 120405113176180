
import Vue from 'vue'
import { mapActions } from 'vuex'
import CategoryExhibitionMainView from '../../views/category/CategoryExhibitionMain.vue'
export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryExhibitionMain',
  components: {
    CategoryExhibitionMainView,
  },
  async created() {
    try {
      this.fetchLoading({ yn: true })
      await this.fetchData()
    } catch (error) {
      console.error(error)
    } finally {
      this.fetchLoading({ yn: false })
    }
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CategoryStore', ['fetchCategoryPlanex']),
    async fetchData() {
      await this.fetchCategoryPlanex()
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchCategoryPlanex(): Promise<void>
  // Component
  fetchData(): void
}
interface Computed {}
